import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import Breadcrumb from "../components/Common/Breadcrumb";
import Container from "../components/Common/Container";
import ProductSlider from "../components/Common/ProductSlider";
import Title from "../components/Common/Title";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
import { fetchMainProducts } from "../services/home";
import {
  checkInventory,
  checkOutOfStock,
  fetchProductDetail,
} from "../services/product-detail";
import { SAMPLE_DESCRIPTION } from "../shared/constant";
import { CartItem, Characteristic } from "../shared/types";
import {
  formatVietnamDong,
  getCategoryNameFromSlug,
  getTextFromHTML,
  saveRecentlyViewedProduct,
  translateCharacteristicName,
} from "../shared/utils";
import useCartStore from "../store/cartStore";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Skeleton from "../components/Common/Skeleton";
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

const ProductPage2 = () => {
  const { productId } = useParams();
  const { categorySlug } = useParams();
  const navigate = useNavigate();

  // số lượng sản phẩm đã chọn
  const [quantity, setQuantity] = useState<number>(1);
  const [availability, setAvailability] = useState(true);

  // reset số lượng về 0 khi đổi sang xem sản phẩm khác
  useEffect(() => {
    setSelectedValues({});
    setQuantity(1);
    setAvailability(true);
    setInventory(null);
    if (productId) saveRecentlyViewedProduct(productId);
  }, [productId]);

  useEffect(() => {
    const checkProductAvailability = async () => {
      if (productId) {
        const res = await checkOutOfStock(productId);

        setAvailability(res.data.qty > 0);
        console.warn({ a: res.data });
      }
    };

    checkProductAvailability();
  }, [productId]);

  // giảm số lượng đã chọn
  const increment = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // tăng số lượng đã chọn
  const decrement = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
  };

  // thêm sp vào giỏ hàng
  const addItemToCart = useCartStore((state) => state.addItemToCart);

  // thuộc tính lô được chọn
  const [selectedValues, setSelectedValues] = useState<Characteristic>({});

  // số lượng tồn kho
  const [inventory, setInventory] = useState<number | null>(null);

  // const handleValueSelect = async (
  //   characteristicCode: string,
  //   value: string
  // ) => {
  //   setSelectedValues((prevSelectedValues) => {
  //     const key = characteristicCode as keyof Characteristic;
  //     // Check if the same characteristicCode and value are already selected
  //     if (prevSelectedValues[key] === value) {
  //       // If selected already, remove it by spreading the previous object and deleting the key
  //       const updatedSelectedValues = { ...prevSelectedValues };
  //       delete updatedSelectedValues[key]; // Remove the selected characteristic
  //       return updatedSelectedValues;
  //     } else {
  //       // Otherwise, add or update the selection
  //       return {
  //         ...prevSelectedValues,
  //         [characteristicCode]: value,
  //       };
  //     }
  //   });

  //   // Sau khi setSelectedValues, tạo mảng properties để gửi API
  //   const updatedValues = {
  //     ...selectedValues,
  //     [characteristicCode]: value,
  //   };

  //   setSelectedValues(updatedValues);

  //   const properties = Object.values(updatedValues).filter(Boolean);

  //   if (properties.length) {
  //     try {
  //       const response = await checkInventory(productId!, properties);
  //       setInventory(response.data); // Cập nhật số lượng tồn kho
  //     } catch (error) {
  //       console.error("Error fetching inventory:", error);
  //       setInventory(null); // Nếu lỗi, đặt lại inventory
  //     }
  //   }
  // };
  const handleValueSelect = async (
    characteristicCode: string,
    value: string
  ) => {
    // Cập nhật selectedValues và xác định nếu cần bỏ chọn
    setSelectedValues((prevSelectedValues) => {
      const key = characteristicCode as keyof Characteristic;

      // Kiểm tra nếu giá trị đã được chọn, thì bỏ chọn
      if (prevSelectedValues[key] === value) {
        const updatedSelectedValues = { ...prevSelectedValues };
        delete updatedSelectedValues[key]; // Xóa giá trị đã chọn
        updateInventory(updatedSelectedValues); // Gọi kiểm tra tồn kho với giá trị cập nhật
        return updatedSelectedValues;
      } else {
        // Thêm hoặc cập nhật giá trị mới
        const updatedSelectedValues = {
          ...prevSelectedValues,
          [characteristicCode]: value,
        };
        updateInventory(updatedSelectedValues); // Gọi kiểm tra tồn kho với giá trị cập nhật
        return updatedSelectedValues;
      }
    });
  };

  // Hàm phụ để kiểm tra tồn kho
  const updateInventory = async (updatedValues: Characteristic) => {
    const properties = Object.values(updatedValues).filter(Boolean);
    if (properties.length) {
      try {
        const response = await checkInventory(productId!, properties);
        setInventory(response.data); // Cập nhật số lượng tồn kho
      } catch (error) {
        console.error("Error fetching inventory:", error);
        setInventory(null); // Đặt lại tồn kho nếu có lỗi
      }
    } else {
      setInventory(null); // Không có thuộc tính nào, không hiển thị tồn kho
    }
  };

  const { error: errorProductDetail, data: dataProductDetail } = useQuery({
    queryKey: [productId, "product-detail"],
    queryFn: () => fetchProductDetail(productId),
  });

  const { error: errorMainProduct, data: dataMainProduct } = useQuery({
    queryKey: ["mainProducts"],
    queryFn: fetchMainProducts,
  });

  if (errorMainProduct) {
    return <div>Error fetching main products</div>;
  }

  if (errorProductDetail) {
    return <div>Error fetching product detail</div>;
  }

  const breadcrumbItems = [
    { label: "Trang chủ", link: "/" },
    {
      label: getCategoryNameFromSlug(categorySlug) || "",
      link: `/san-pham/${categorySlug}`,
    },
    { label: dataProductDetail?.data.productName || "" },
  ];

  ///////////////////////////////////////////////////////////////////////////////
  // TÌM SẢN PHẨM LIÊN QUAN
  const mainProductBrand = dataProductDetail?.data.brand;
  const relatedProducts = dataMainProduct?.data
    .find(
      (mainProduct) =>
        mainProduct.categoryName === dataProductDetail?.data.categoryName
    )
    ?.products.filter((product) => product.productId !== productId)
    .sort((a, b) => {
      // Move products with the same brand as the main product to the top
      if (a.brand === mainProductBrand && b.brand !== mainProductBrand) {
        return -1; // a should come before b
      }
      if (a.brand !== mainProductBrand && b.brand === mainProductBrand) {
        return 1; // b should come before a
      }
      return 0; // Keep order if brands are the same or both are different from main brand
    });
  /////////////////////////////////////////////////////////////////////////////
  // LẤY SẢN PHẨM VỪA XEM
  const getRecentlyViewedProducts = () => {
    const recentlyViewedIds =
      JSON.parse(localStorage.getItem("RecentlyViewedProducts") || "[]") || [];

    // Get products that match the recently viewed IDs, keeping the original order, then reverse it
    const recentlyViewedProducts = recentlyViewedIds
      .map((id: string) =>
        dataMainProduct?.data
          ?.flatMap((data) => data.products)
          .find((product) => product.productId === id)
      )
      .filter(Boolean) // Remove any null or undefined values (in case an ID is not found)
      .reverse()
      .slice(1); // bỏ qua phần tử đầu tiên, vì phần tử đầu tiên là sản phẩm đang xem

    return recentlyViewedProducts;
  };

  const recentlyViewedProducts = getRecentlyViewedProducts();
  ////////////////////////////////////////////////////////////////////////////////

  const handleAddToCart = () => {
    if (
      Object.values(selectedValues).filter(Boolean).length !==
      dataProductDetail?.data.characteristic?.length
    ) {
      toast.error("Vui lòng chọn màu sắc/kích cỡ của sản phẩm", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (inventory === 0) {
      toast.error("Sản phẩm này đã hết hàng trong kho", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (quantity !== null && inventory !== null && quantity > inventory) {
      toast.error("Số lượng bạn chọn vượt quá hàng tồn kho", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    toast.success("Thêm sản phẩm vào giỏ hàng thành công", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

    const product: CartItem = {
      id: productId!,
      name: dataProductDetail?.data.productName || "",
      brand: dataProductDetail?.data.brand || "",
      img: dataProductDetail?.data.imageUrl || "",
      price: dataProductDetail?.data.price || 0,
      quantity: quantity,
      totalPrice: (dataProductDetail?.data.price || 0) * quantity,
      characteristic: selectedValues,
      productCode: dataProductDetail?.data.productCode || "",
      ERPProductCode: dataProductDetail?.data.erpProductCode,
    };

    addItemToCart(product);
  };

  // Thêm hàm xử lý 'Mua ngay'
  const handleBuyNow = () => {
    if (
      Object.values(selectedValues).filter(Boolean).length !==
      dataProductDetail?.data.characteristic?.length
    ) {
      toast.error("Vui lòng chọn màu sắc/kích cỡ của sản phẩm", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (inventory === 0) {
      toast.error("Sản phẩm này đã hết hàng trong kho", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (quantity !== null && inventory !== null && quantity > inventory) {
      toast.error("Số lượng bạn chọn vượt quá hàng tồn kho", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    // Thêm sản phẩm vào giỏ hàng
    const product: CartItem = {
      id: productId!,
      name: dataProductDetail?.data.productName || "",
      brand: dataProductDetail?.data.brand || "",
      img: dataProductDetail?.data.imageUrl || "",
      price: dataProductDetail?.data.price || 0,
      quantity: quantity,
      totalPrice: (dataProductDetail?.data.price || 0) * quantity,
      characteristic: selectedValues,
      productCode: dataProductDetail?.data.productCode || "",
      ERPProductCode: dataProductDetail?.data.erpProductCode,
    };

    addItemToCart(product);

    // Chuyển đến trang thanh toán
    navigate("/thanh-toan");
  };

  const roundedDiscountPercentage = Math.round(
    dataProductDetail?.data.discountPercentage || 0
  );

  const images =
    dataProductDetail?.data.allImages?.map((imageUrl) => ({
      original: imageUrl || "",
      thumbnail: imageUrl || "",
    })) || [];

  console.log(selectedValues);

  return (
    <div>
      <ToastContainer />

      <Helmet>
        {/* Title and description for SEO */}
        <title>{`${dataProductDetail?.data.productName} | Vua Vợt - ${dataProductDetail?.data.brand}`}</title>
        <meta
          name="description"
          content={`Mua ${
            dataProductDetail?.data.productName
          } - Sản phẩm từ thương hiệu ${
            dataProductDetail?.data.brand
          } với giá ${formatVietnamDong(
            dataProductDetail?.data.price
          )}. Tìm hiểu thêm các sản phẩm cầu lông tại Vua Vợt.`}
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content={`${dataProductDetail?.data.productName} | Vua Vợt - ${dataProductDetail?.data.brand}`}
        />
        <meta
          property="og:description"
          content={`Khám phá ${dataProductDetail?.data.productName} từ ${
            dataProductDetail?.data.brand
          }, một sản phẩm chất lượng cao cho người yêu cầu lông. Giá: ${formatVietnamDong(
            dataProductDetail?.data.price
          )}.`}
        />
        <meta
          property="og:image"
          content={
            dataProductDetail?.data.imageUrl ||
            "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
          }
        />
        <meta
          property="og:url"
          content={`https://vuavot.com/san-pham/${productId}`}
        />
        <meta property="og:type" content="product" />

        {/* Zalo */}
        <meta
          property="zalo:title"
          content={`${dataProductDetail?.data.productName} | Vua Vợt - ${dataProductDetail?.data.brand}`}
        />
        <meta
          property="zalo:description"
          content={`Sản phẩm ${
            dataProductDetail?.data.productName
          } từ thương hiệu ${
            dataProductDetail?.data.brand
          }. Giá ưu đãi: ${formatVietnamDong(
            dataProductDetail?.data.price
          )} tại Vua Vợt.`}
        />
        <meta
          property="zalo:image"
          content={
            dataProductDetail?.data.imageUrl ||
            "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
          }
        />
        <meta
          property="zalo:url"
          content={`https://vuavot.com/san-pham/${productId}`}
        />
      </Helmet>

      <Header />
      <Container className="mb-10">
        <HeroWithOverlay />
        <Breadcrumb items={breadcrumbItems} />

        {!dataProductDetail ? (
          <ProductDetailSkeleton />
        ) : (
          <div className="flex flex-col md:flex-row md:gap-8 lg:gap-16 mb-12">
            {/* LEFT SIDE: PRODUCT IMAGE */}
            <div className="md:w-1/2 lg:w-[45%] w-full relative">
              <ImageGallery
                items={images}
                showPlayButton={false}
                showNav={false}
                showFullscreenButton={false}
                showThumbnails={(images?.length || 0) > 1}
                additionalClass="aspect-square"
              />
              {dataProductDetail?.data.isPopular && (
                <div className="absolute top-3 right-2">
                  <span className="bg-green-500 tracking-wider text-white font-bold text-sm px-3 py-2 rounded-lg">
                    HOT
                  </span>
                </div>
              )}
            </div>

            {/* RIGHT SIDE: PRODUCT DETAILS */}
            <div className="md:w-1/2 lg:w-[55%] w-full mt-6 md:mt-0">
              {/* PRODUCT NAME */}
              <h1 className="text-2xl md:text-3xl font-extrabold mb-2">
                {dataProductDetail?.data.productName}
              </h1>

              {/* BRAND */}
              <div className="flex flex-col md:flex-row md:items-center mb-4">
                <p className="pr-0 md:pr-2 border-r-0 md:border-r border-gray-300">
                  Thương hiệu:{" "}
                  <span className="text-primary">
                    {dataProductDetail?.data.brand}
                  </span>
                </p>
                <p className="mt-2 md:mt-0 md:pl-2">
                  CODE:{" "}
                  <span className="text-primary">
                    {dataProductDetail?.data.productCode}
                  </span>
                </p>
                <p className="mt-2 md:mt-0 md:pl-2">
                  Tình trạng:{" "}
                  <span className="text-primary">
                    {availability ? "Còn hàng" : "Tạm hết hàng"}
                  </span>
                </p>
              </div>

              {/* PRICE */}
              <div className="flex md:flex-row gap-4 items-center mb-6">
                <p className="font-semibold text-xl md:text-2xl text-primary ">
                  {dataProductDetail?.data.price?.toLocaleString()}₫
                </p>
                {dataProductDetail?.data.manufacturerPrice &&
                  dataProductDetail?.data.manufacturerPrice !==
                    dataProductDetail?.data.price && (
                    <p className="text-gray-400 line-through text-base md:text-lg">
                      {dataProductDetail?.data.manufacturerPrice?.toLocaleString()}
                      ₫
                    </p>
                  )}
                {roundedDiscountPercentage > 0 && (
                  <div className="bg-primary rounded-lg px-2 py-1 text-white text-sm">
                    Tiết kiệm {roundedDiscountPercentage}%
                  </div>
                )}
              </div>

              {/* DESCRIPTION */}
              <p className="text-gray-700 mb-8 text-base md:text-lg pt-5 border-t border-gray-300 text-justify">
                {getTextFromHTML(
                  dataProductDetail?.data.shortDescription || ""
                ) || SAMPLE_DESCRIPTION}
                {!dataProductDetail?.data.shortDescription && (
                  <p className="italic mt-3">
                    Lưu ý: Đây chỉ là mô tả mẫu, thông tin chính thức về sản
                    phẩm sẽ được cập nhật sau.
                  </p>
                )}
              </p>

              {/* CHARACTERISTICS */}
              <div className="mb-5">
                {dataProductDetail?.data.characteristic
                  ?.filter(
                    (characteristic) =>
                      characteristic.characteristicValues.length > 0
                  )
                  .map((characteristic) => (
                    <div
                      key={characteristic.characteristicType.name}
                      className="flex flex-col md:flex-row md:items-center gap-5 mb-5"
                    >
                      <p className="font-medium">
                        {translateCharacteristicName(
                          characteristic.characteristicType.name
                        ) + ": "}
                      </p>
                      <div className="flex flex-wrap gap-2 md:gap-4">
                        {characteristic.characteristicValues.map((value) => (
                          <button
                            key={value.valueCode}
                            onClick={() =>
                              handleValueSelect(
                                characteristic.characteristicType.code,
                                value.valueCode
                              )
                            }
                            className={`px-2 py-1 border-2 border-gray-200 hover:text-primary hover:border-primary transition duration-300 ${
                              Object.values(selectedValues).includes(
                                value.valueCode
                              ) && "!border-primary !text-primary"
                            }`}
                          >
                            {value.valueName}
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="flex gap-6 items-center mb-6">
                {/* QUANTITY */}
                <div className="flex md:flex-row gap-5 items-center">
                  <p className="font-medium">Số lượng</p>
                  <div className="flex items-center gap-4 md:gap-6 bg-lightWhite">
                    <button
                      onClick={decrement}
                      className="px-3 md:px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg transition duration-300"
                    >
                      -
                    </button>
                    <span className="text-lg font-bold">{quantity}</span>
                    <button
                      onClick={increment}
                      className="px-3 md:px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg transition duration-300"
                    >
                      +
                    </button>
                  </div>
                </div>
                {/* Hiển thị tồn kho */}
                {/* {inventory && inventory > 0 && (
                  <p className=" text-gray-500">
                    Tồn kho: <span className="font-medium">{inventory}</span>
                  </p>
                )}
                {inventory && inventory === 0 && (
                  <p className=" text-red-500 italic">Hết hàng</p>
                )} */}

                {inventory !== null &&
                  inventory > 0 &&
                  quantity <= inventory && (
                    <div className="flex gap-1 items-center">
                      <IoCheckmark size={20} className="text-green-500" />
                      <p className=" text-green-500 italic ">Còn hàng</p>
                    </div>
                  )}
                {inventory !== null && inventory === 0 && (
                  <div className="flex gap-1 items-center">
                    <RxCross2 size={20} className="text-red-500" />
                    <p className=" text-red-500 italic ">Tạm hết hàng</p>
                  </div>
                )}
                {inventory !== null &&
                  inventory > 0 &&
                  quantity > inventory && (
                    <div className="flex gap-1 items-center">
                      <RxCross2 size={20} className="text-red-500" />
                      <p className=" text-red-500 italic ">Không đủ hàng</p>
                    </div>
                  )}
              </div>

              {/* ACTION BUTTONS */}
              <div className="flex flex-col gap-2 md:flex-row md:gap-5 mt-6">
                <button
                  onClick={handleAddToCart}
                  className="uppercase bg-black/90 w-full md:flex-1 py-4 rounded-sm text-white font-medium hover:bg-black transition duration-300"
                >
                  Thêm vào giỏ hàng
                </button>
                <button
                  onClick={handleBuyNow}
                  className="uppercase bg-primary w-full md:flex-1 py-4 rounded-sm text-white font-medium hover:brightness-125 transition duration-300"
                >
                  Mua ngay
                </button>
              </div>
            </div>
          </div>
        )}

        {/* PRODUCT DETAILS */}
        <p className="font-semibold text-xl md:text-2xl mb-3">Mô tả chi tiết</p>
        <div
          dangerouslySetInnerHTML={{
            __html: dataProductDetail?.data.longDescription as string,
          }}
          className="text-base md:text-lg text-justify mb-12"
        />

        {/* RELATED PRODUCTS */}
        <Title
          title="Sản phẩm liên quan"
          seeMoreUrl={`/san-pham/${categorySlug}`}
        />
        <ProductSlider products={relatedProducts} />

        {/* RECENTLY VIEWED PRODUCTS */}
        <Title title="Sản phẩm vừa xem" className="mt-10" />
        <ProductSlider products={recentlyViewedProducts} />
      </Container>
      <Footer />
    </div>
  );
};

export default ProductPage2;

const ProductDetailSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row md:gap-8 lg:gap-16 mb-12">
      {/* LEFT SIDE: PRODUCT IMAGE SKELETON */}
      <div className="md:w-1/2 lg:w-[45%] w-full relative">
        <Skeleton className="w-full h-0 pb-[100%] rounded-lg" />{" "}
        {/* Image Skeleton */}
        <Skeleton className="absolute top-3 right-2 w-16 h-6 rounded-lg" />{" "}
        {/* "HOT" badge */}
      </div>

      {/* RIGHT SIDE: PRODUCT DETAILS SKELETON */}
      <div className="md:w-1/2 lg:w-[55%] w-full mt-6 md:mt-0">
        {/* PRODUCT NAME */}
        <Skeleton className="h-8 w-3/4 mb-2" /> {/* Product Title Skeleton */}
        {/* BRAND */}
        <div className="flex flex-col md:flex-row md:items-center mb-4 gap-4">
          <Skeleton className="h-4 w-1/2" /> {/* Brand Skeleton */}
          <Skeleton className="h-4 w-1/3" /> {/* Product Code Skeleton */}
        </div>
        {/* PRICE */}
        <div className="flex md:flex-row gap-4 items-center mb-6">
          <Skeleton className="h-6 w-1/3" /> {/* Price Skeleton */}
          <Skeleton className="h-5 w-1/4" /> {/* Original Price Skeleton */}
          <Skeleton className="h-5 w-1/4 rounded-lg" />{" "}
          {/* Discount Badge Skeleton */}
        </div>
        {/* DESCRIPTION */}
        <Skeleton className="h-4 w-full mb-2" />
        <Skeleton className="h-4 w-full mb-2" />
        <Skeleton className="h-4 w-3/4 mb-8" />
        {/* CHARACTERISTICS */}
        <div className="mb-5">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row md:items-center gap-5 mb-5"
            >
              <Skeleton className="h-4 w-1/4" /> {/* Characteristic Title */}
              <div className="flex gap-2">
                <Skeleton className="h-6 w-10 rounded-md" />
                <Skeleton className="h-6 w-10 rounded-md" />
                <Skeleton className="h-6 w-10 rounded-md" />
              </div>
            </div>
          ))}
        </div>
        {/* QUANTITY */}
        <div className="flex md:flex-row gap-5 items-center mb-6">
          <Skeleton className="h-4 w-1/4" /> {/* Quantity Label */}
          <div className="flex items-center gap-4 md:gap-6">
            <Skeleton className="h-10 w-10 rounded-lg" /> {/* Minus Button */}
            <Skeleton className="h-6 w-10" /> {/* Quantity Number */}
            <Skeleton className="h-10 w-10 rounded-lg" /> {/* Plus Button */}
          </div>
        </div>
        {/* ACTION BUTTONS */}
        <div className="flex flex-col gap-2 md:flex-row md:gap-5 mt-6">
          <Skeleton className="h-12 w-full md:flex-1 rounded-sm" />{" "}
          {/* Add to Cart Button */}
          <Skeleton className="h-12 w-full md:flex-1 rounded-sm" />{" "}
          {/* Buy Now Button */}
        </div>
      </div>
    </div>
  );
};

// import { useQuery } from "@tanstack/react-query";
// import { useEffect, useState } from "react";
// import { Helmet } from "react-helmet-async";
// import { useNavigate, useParams } from "react-router-dom";
// import { Bounce, ToastContainer, toast } from "react-toastify";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import ProductSlider from "../components/Common/ProductSlider";
// import Title from "../components/Common/Title";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
// import { fetchMainProducts } from "../services/home";
// import { fetchProductDetail } from "../services/product-detail";
// import { SAMPLE_DESCRIPTION } from "../shared/constant";
// import { CartItem, Characteristic } from "../shared/types";
// import {
//   formatVietnamDong,
//   getCategoryNameFromSlug,
//   getTextFromHTML,
//   saveRecentlyViewedProduct,
//   translateCharacteristicName,
// } from "../shared/utils";
// import useCartStore from "../store/cartStore";

// import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";

// const ProductPage2 = () => {
//   const { productId } = useParams();
//   const { categorySlug } = useParams();
//   const [quantity, setQuantity] = useState<number>(1);

//   useEffect(() => {
//     setSelectedValues({});
//     if (productId) saveRecentlyViewedProduct(productId);
//   }, [productId]);

//   const increment = () => {
//     setQuantity((prevQuantity) => prevQuantity + 1);
//   };
//   const decrement = () => {
//     setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
//   };
//   const addItemToCart = useCartStore((state) => state.addItemToCart);
//   const navigate = useNavigate();

//   const [selectedValues, setSelectedValues] = useState<Characteristic>({});

//   const handleValueSelect = (characteristicCode: string, value: string) => {
//     setSelectedValues((prevSelectedValues) => {
//       const key = characteristicCode as keyof Characteristic;
//       // Check if the same characteristicCode and value are already selected
//       if (prevSelectedValues[key] === value) {
//         // If selected already, remove it by spreading the previous object and deleting the key
//         const updatedSelectedValues = { ...prevSelectedValues };
//         delete updatedSelectedValues[key]; // Remove the selected characteristic
//         return updatedSelectedValues;
//       } else {
//         // Otherwise, add or update the selection
//         return {
//           ...prevSelectedValues,
//           [characteristicCode]: value,
//         };
//       }
//     });
//   };

//   const {
//     isPending: isLoadingProductDetail,
//     error: errorProductDetail,
//     data: dataProductDetail,
//   } = useQuery({
//     queryKey: [productId, "product-detail"],
//     queryFn: () => fetchProductDetail(productId),
//   });

//   const {
//     isPending: isLoadingMainProduct,
//     error: errorMainProduct,
//     data: dataMainProduct,
//   } = useQuery({
//     queryKey: ["mainProducts"],
//     queryFn: fetchMainProducts,
//   });

//   if (isLoadingMainProduct) {
//     return <div>Loading...</div>;
//   }

//   if (errorMainProduct) {
//     return <div>Error fetching main products</div>;
//   }

//   if (isLoadingProductDetail) {
//     return <div>Loading...</div>;
//   }

//   if (errorProductDetail) {
//     return <div>Error fetching product detail</div>;
//   }

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     {
//       label: getCategoryNameFromSlug(categorySlug) || "",
//       link: `/san-pham/${categorySlug}`,
//     },
//     { label: dataProductDetail?.data.productName },
//   ];

//   ///////////////////////////////////////////////////////////////////////////////
//   // TÌM SẢN PHẨM LIÊN QUAN
//   const mainProductBrand = dataProductDetail?.data.brand;
//   const relatedProducts = dataMainProduct?.data
//     .find(
//       (mainProduct) =>
//         mainProduct.categoryName === dataProductDetail?.data.categoryName
//     )
//     ?.products.filter((product) => product.productId !== productId)
//     .sort((a, b) => {
//       // Move products with the same brand as the main product to the top
//       if (a.brand === mainProductBrand && b.brand !== mainProductBrand) {
//         return -1; // a should come before b
//       }
//       if (a.brand !== mainProductBrand && b.brand === mainProductBrand) {
//         return 1; // b should come before a
//       }
//       return 0; // Keep order if brands are the same or both are different from main brand
//     });
//   /////////////////////////////////////////////////////////////////////////////
//   // LẤY SẢN PHẨM VỪA XEM
//   const getRecentlyViewedProducts = () => {
//     const recentlyViewedIds =
//       JSON.parse(localStorage.getItem("RecentlyViewedProducts") || "[]") || [];

//     // Get products that match the recently viewed IDs, keeping the original order, then reverse it
//     const recentlyViewedProducts = recentlyViewedIds
//       .map((id: string) =>
//         dataMainProduct?.data
//           ?.flatMap((data) => data.products)
//           .find((product) => product.productId === id)
//       )
//       .filter(Boolean) // Remove any null or undefined values (in case an ID is not found)
//       .reverse()
//       .slice(1); // bỏ qua phần tử đầu tiên, vì phần tử đầu tiên là sản phẩm đang xem

//     return recentlyViewedProducts;
//   };

//   const recentlyViewedProducts = getRecentlyViewedProducts();
//   ////////////////////////////////////////////////////////////////////////////////

//   const handleAddToCart = () => {
//     if (
//       Object.values(selectedValues).filter(Boolean).length !==
//       dataProductDetail?.data.characteristic?.length
//     ) {
//       toast.error("Vui lòng chọn màu sắc/kích cỡ của sản phẩm", {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//         transition: Bounce,
//       });
//       return;
//     }

//     toast.success("Thêm sản phẩm vào giỏ hàng thành công", {
//       position: "top-right",
//       autoClose: 2000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//       transition: Bounce,
//     });

//     const product: CartItem = {
//       id: productId!,
//       name: dataProductDetail?.data.productName,
//       brand: dataProductDetail?.data.brand,
//       img: dataProductDetail?.data.imageUrl || "",
//       price: dataProductDetail?.data.price || 0,
//       quantity: quantity,
//       totalPrice: (dataProductDetail?.data.price || 0) * quantity,
//       characteristic: selectedValues,
//       productCode: dataProductDetail?.data.productCode,
//       ERPProductCode: dataProductDetail?.data.erpProductCode,
//     };

//     addItemToCart(product);
//   };

//   // Thêm hàm xử lý 'Mua ngay'
//   const handleBuyNow = () => {
//     if (
//       Object.values(selectedValues).filter(Boolean).length !==
//       dataProductDetail?.data.characteristic?.length
//     ) {
//       toast.error("Vui lòng chọn màu sắc/kích cỡ của sản phẩm", {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//         transition: Bounce,
//       });
//       return;
//     }

//     // Thêm sản phẩm vào giỏ hàng
//     const product: CartItem = {
//       id: productId!,
//       name: dataProductDetail?.data.productName,
//       brand: dataProductDetail?.data.brand,
//       img: dataProductDetail?.data.imageUrl || "",
//       price: dataProductDetail?.data.price || 0,
//       quantity: quantity,
//       totalPrice: (dataProductDetail?.data.price || 0) * quantity,
//       characteristic: selectedValues,
//       productCode: dataProductDetail?.data.productCode,
//       ERPProductCode: dataProductDetail?.data.erpProductCode,
//     };

//     addItemToCart(product);

//     // Chuyển đến trang thanh toán
//     navigate("/thanh-toan");
//   };

//   const roundedDiscountPercentage = Math.round(
//     dataProductDetail?.data.discountPercentage || 0
//   );

//   const images = dataProductDetail?.data.allImages?.map((imageUrl) => ({
//     original: imageUrl,
//     thumbnail: imageUrl,
//   }));

//   return (
//     <div>
//       <ToastContainer />

//       <Helmet>
//         {/* Title and description for SEO */}
//         <title>{`${dataProductDetail.data.productName} | Vua Vợt - ${dataProductDetail.data.brand}`}</title>
//         <meta
//           name="description"
//           content={`Mua ${
//             dataProductDetail.data.productName
//           } - Sản phẩm từ thương hiệu ${
//             dataProductDetail.data.brand
//           } với giá ${formatVietnamDong(
//             dataProductDetail.data.price
//           )}. Tìm hiểu thêm các sản phẩm cầu lông tại Vua Vợt.`}
//         />

//         {/* Open Graph / Facebook */}
//         <meta
//           property="og:title"
//           content={`${dataProductDetail.data.productName} | Vua Vợt - ${dataProductDetail.data.brand}`}
//         />
//         <meta
//           property="og:description"
//           content={`Khám phá ${dataProductDetail.data.productName} từ ${
//             dataProductDetail.data.brand
//           }, một sản phẩm chất lượng cao cho người yêu cầu lông. Giá: ${formatVietnamDong(
//             dataProductDetail.data.price
//           )}.`}
//         />
//         <meta
//           property="og:image"
//           content={
//             dataProductDetail.data.imageUrl ||
//             "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//           }
//         />
//         <meta
//           property="og:url"
//           content={`https://vuavot.com/san-pham/${productId}`}
//         />
//         <meta property="og:type" content="product" />

//         {/* Zalo */}
//         <meta
//           property="zalo:title"
//           content={`${dataProductDetail.data.productName} | Vua Vợt - ${dataProductDetail.data.brand}`}
//         />
//         <meta
//           property="zalo:description"
//           content={`Sản phẩm ${
//             dataProductDetail.data.productName
//           } từ thương hiệu ${
//             dataProductDetail.data.brand
//           }. Giá ưu đãi: ${formatVietnamDong(
//             dataProductDetail.data.price
//           )} tại Vua Vợt.`}
//         />
//         <meta
//           property="zalo:image"
//           content={
//             dataProductDetail.data.imageUrl ||
//             "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//           }
//         />
//         <meta
//           property="zalo:url"
//           content={`https://vuavot.com/san-pham/${productId}`}
//         />
//       </Helmet>

//       <Header />
//       <Container className="mb-10">
//         <HeroWithOverlay />
//         <Breadcrumb items={breadcrumbItems} />

//         <div className="flex flex-col lg:flex-row lg:gap-16 mb-12">
//           {/* BÊN TRÁI: HÌNH ẢNH SẢN PHẨM */}
//           <div className="lg:w-[45%] w-full relative">
//             <ImageGallery
//               items={images}
//               showPlayButton={false}
//               showNav={false}
//               showFullscreenButton={false}
//               showThumbnails={images.length > 1} // Hide thumbnails if only 1 image
//               additionalClass="aspect-square"
//             />
//             {dataProductDetail.data.isPopular && (
//               <div className="absolute top-3 right-2">
//                 <span className="bg-green-500 tracking-wider text-white font-bold text-sm px-3 py-2 rounded-lg">
//                   HOT
//                 </span>
//               </div>
//             )}
//           </div>

//           {/* BÊN PHẢI: THÔNG TIN SẢN PHẨM */}
//           <div className="lg:w-[65%] w-full mt-6 lg:mt-0">
//             {/* TÊN SẢN PHẨM */}
//             <h1 className="text-3xl font-extrabold mb-1">
//               {getCategoryNameFromSlug(categorySlug)}{" "}
//               {dataProductDetail.data.productName} Chính hãng
//             </h1>

//             {/* THƯƠNG HIỆU */}
//             <div className="flex mb-4">
//               <p className="pr-2 border-r border-gray-300">
//                 Thương hiệu:{" "}
//                 <span className="text-primary">
//                   {dataProductDetail.data.brand}
//                 </span>
//               </p>
//               <p className="pl-2">
//                 CODE:{" "}
//                 <span className="text-primary">
//                   {dataProductDetail.data.productCode}
//                 </span>
//               </p>
//             </div>

//             {/* GIÁ */}
//             <div className="flex gap-4 items-center mb-6">
//               <p className="font-semibold text-2xl text-primary">
//                 {dataProductDetail.data.price?.toLocaleString()}₫
//               </p>
//               <p className="text-gray-400 line-through text-lg">
//                 {dataProductDetail.data.manufacturerPrice?.toLocaleString()}₫
//               </p>
//               {roundedDiscountPercentage > 0 && (
//                 <div className="bg-primary rounded-lg px-2 py-1 text-white text-sm">
//                   Tiết kiệm {roundedDiscountPercentage}%
//                 </div>
//               )}
//             </div>

//             {/* MIÊU TẢ */}
//             <p className="text-gray-700 mb-8 text-lg pt-5 border-t border-gray-300 text-justify">
//               {getTextFromHTML(dataProductDetail.data.shortDescription || "") ||
//                 SAMPLE_DESCRIPTION}
//               {!dataProductDetail.data.shortDescription && (
//                 <p className="italic mt-3">
//                   Lưu ý: Đây chỉ là mô tả mẫu, thông tin chính thức về sản phẩm
//                   sẽ được cập nhật sau.
//                 </p>
//               )}
//             </p>

//             {/*THUỘC TÍNH LÔ */}
//             <div className="mb-5">
//               {dataProductDetail.data.characteristic
//                 ?.filter(
//                   (characteristic) =>
//                     characteristic.characteristicValues.length > 0
//                 )
//                 .map((characteristic) => (
//                   <div
//                     key={characteristic.characteristicType.name}
//                     className="flex items-center gap-5 mb-5"
//                   >
//                     <p className="font-medium">
//                       {translateCharacteristicName(
//                         characteristic.characteristicType.name
//                       ) + ": "}
//                     </p>
//                     <div className="flex flex-wrap gap-4">
//                       {characteristic.characteristicValues.map((value) => (
//                         <button
//                           key={value.valueCode}
//                           onClick={() =>
//                             handleValueSelect(
//                               characteristic.characteristicType.code,
//                               value.valueCode
//                             )
//                           }
//                           className={`px-2 py-1 border-2 border-gray-200 hover:text-primary hover:border-primary transition duration-300 ${
//                             Object.values(selectedValues).includes(
//                               value.valueCode
//                             ) && "!border-primary !text-primary"
//                           }`}
//                         >
//                           {value.valueName}
//                         </button>
//                       ))}
//                     </div>
//                   </div>
//                 ))}
//             </div>

//             {/* SỐ LƯỢNG */}
//             <div className="flex gap-5 items-center">
//               <p className="font-medium">Số lượng</p>
//               <div className="flex items-center gap-6 bg-lightWhite">
//                 {/* Decrement Button */}
//                 <button
//                   onClick={decrement}
//                   className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg transition duration-300"
//                 >
//                   -
//                 </button>

//                 {/* Display the current quantity */}
//                 <span className="text-lg font-bold">{quantity}</span>

//                 {/* Increment Button */}
//                 <button
//                   onClick={increment}
//                   className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg transition duration-300"
//                 >
//                   +
//                 </button>
//               </div>
//             </div>

//             {/* HAI BUTTON  */}
//             <div className="flex mt-6 gap-5">
//               <button
//                 onClick={handleAddToCart}
//                 className="uppercase bg-black/90 flex-1 py-4 rounded-sm text-white font-medium hover:bg-black transition duration-300"
//               >
//                 Thêm vào giỏ hàng
//               </button>
//               <button
//                 onClick={handleBuyNow}
//                 className="uppercase bg-primary flex-1 py-4 rounded-sm text-white font-medium hover:brightness-125 transition duration-300"
//               >
//                 Mua ngay
//               </button>
//             </div>
//           </div>
//         </div>

//         <p className="font-semibold text-2xl mb-3">Mô tả chi tiết</p>
//         <div
//           dangerouslySetInnerHTML={{
//             __html: dataProductDetail.data.longDescription as string,
//           }}
//           className="text-lg text-justify mb-12"
//         />

//         {/* <p className="font-semibold text-2xl mt-7 mb-3">Sản phẩm liên quan</p> */}
//         <Title
//           title="Sản phẩm liên quan"
//           seeMoreUrl={`/san-pham/${categorySlug}`}
//         />
//         <ProductSlider products={relatedProducts} />

//         <Title title="Sản phẩm vừa xem" className="mt-10" />
//         <ProductSlider products={recentlyViewedProducts} />
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default ProductPage2;
