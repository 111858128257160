import axios from "axios";
import axiosInstance from "../shared/axiosInstance";
import { APISingleResponse, ProductDetail } from "../shared/types";

export const fetchProductDetail = async (
  productId?: string
): Promise<APISingleResponse<ProductDetail>> => {
  try {
    if (!productId) throw new Error("Product ID is null");

    const response = await axiosInstance.get<APISingleResponse<ProductDetail>>(
      `/api/v1/products/${productId}`
    );

    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(
        response.data.message || "Failed to fetch product detail"
      );
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw new Error("An error occurred while fetching product detail");
  }
};

// kiểm tra tồn kho
export const checkInventory = async (
  productId: string,
  properties: string[]
) => {
  try {
    const response = await axios.post(
      "https://banhangz-manufacture.vuavot.com/api/v1/warehouse/check-inventory-by-properties",
      {
        productId,
        properties,
      }
    );
    return response.data; // Trả về dữ liệu từ API
  } catch (error) {
    console.error("Error checking inventory:", error);
    throw error; // Ném lỗi để xử lý bên ngoài nếu cần
  }
};

// kiểm tra tồn kho
export const checkOutOfStock = async (productId: string) => {
  try {
    const response = await axios.get(
      `https://banhangz-manufacture.vuavot.com/api/v1/warehouse/check-inventory-by-product?productId=${productId}`
    );
    return response.data; // Trả về dữ liệu từ API
  } catch (error) {
    console.error("Error checking inventory:", error);
    throw error; // Ném lỗi để xử lý bên ngoài nếu cần
  }
};
